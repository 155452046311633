/*
 * yQuery 1.0.0
 *
 * Based on jQuery and the work to de-implement jQuery done by GitHub:
 * https://githubengineering.com/removing-jquery-from-github-frontend/
 */
'use strict';

var yQuery = function(selector) {
  return new yQuery.fn.init(selector);
}

yQuery.fn = yQuery.prototype = {
  init: function(selector) {
    if(!selector) {
      return yQuery;
    }
    if(typeof selector === "string") {
      var nodeList = document.querySelectorAll(selector);
      return Array.prototype.slice.call(nodeList);
    } else if (yQuery.fn.isFunction(selector)) {
      if (document.attachEvent
          ? document.readyState === "complete"
          : document.readyState !== "loading"
      ){
        selector();
      } else {
        document.addEventListener('DOMContentLoaded', selector);
      }
    }
  },
  isFunction: function(obj) {
    return typeof obj === "function" && typeof obj.nodeType !== "number";
  },
  processNodeArray: function(array, callback){
    var ret = [];
    array.forEach(function(item, index) {
      ret = Array.prototype.concat.call(
          ret,
          callback(item)
      );
    });
    return ret;
  },
  getNodeArray: function(selector, origin) {
    if(selector instanceof Element) {
      return [selector];
    }
    if(!origin) {
      origin = document;
    }
    if( Array.isArray(origin) ) {
      return yQuery.fn.processNodeArray(origin,function(element){
        return yQuery.fn.getNodeArray(selector,element);
      });
    }
    var nodeList = origin.querySelectorAll(selector);
    return Array.prototype.slice.call(nodeList);
  }
}

yQuery[ 'one' ] = function(selector, origin) {
  var res = yQuery.fn.getNodeArray(selector, origin);
  if(res.length > 1) {
    console.warn('yQuery: One node expected, found',res.length);
  }
  return res[0];
}

yQuery[ 'many' ] = function(selector, origin) {
  var res = yQuery.fn.getNodeArray(selector, origin);
  if(res.length < 2) {
    console.warn('yQuery: Many nodes expected, found',res.length);
  }
  return res;
}

yQuery[ 'closest' ] = function(selector, origin) {
  if( Array.isArray(origin) ){
    return yQuery.fn.processNodeArray(origin,function(element){
      return yQuery.closest(selector,element);
    });
  }
  return origin.closest(selector);
}

yQuery[ 'fetch' ] = function(url, settings) {
  return fetch(url, settings);
}

yQuery[ 'on' ] = function(eventName, eventTarget, eventHandler) {
  if(Array.isArray(eventTarget)){
    yQuery.fn.processNodeArray(eventTarget, function(item) {
      item.addEventListener(eventName, eventHandler);
    });
  } else {
    eventTarget.addEventListener(eventName, eventHandler);
  }
}
